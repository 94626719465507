<template>
  <article class="badge" @click="followLink">
    <div class="badge--content">
      <component :is="`h${headingLevel}`" class="title"
        >Ihre Vorteile bei {{ $brand.name }}</component
      >
      <p>Flexibel mit voller Kontrolle im Telefónica Netz unterwegs.</p>

      <Checklist class="checklist">
        <li>Keine Vertragsbindung</li>
        <li>Kein Mindestumsatz</li>
        <li>{{ $brand.name }} nutzt das Netz von Telefónica</li>
        <li>Surfen mit bis zu 50Mbit/s</li>
      </Checklist>
    </div>

    <ButtonComponent
      :clickhandler="followLink"
      :href="$router.resolve(path).href"
      class="badge--button"
      @click.stop
      >Vorteile nutzen</ButtonComponent
    >
  </article>
</template>

<script>
import { scrollTop } from 'src/lib/scroll_to.js'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checklist from 'src/components/common/checklist/checklist.vue'
export default {
  name: 'BenefitsBadge',
  components: { Checklist, ButtonComponent },
  props: {
    headingLevel: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      path: { name: 'PortalTariffOverview' }
    }
  },
  methods: {
    followLink() {
      if (this.$route.name === this.path.name) {
        scrollTop()
      } else {
        this.$router.push(this.path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../badges';
</style>
